import { Injectable } from '@angular/core';
import { GetBusinessFolderModel, IntegrationClient, SaveBusinessFolderModel } from 'projects/difference/webapi/Difference.WebApi';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {
  constructor(private webApi: IntegrationClient) {}

  saveFolder(engagementLetterId: number, businessFolder: SaveBusinessFolderModel): Observable<GetBusinessFolderModel> {
    return this.webApi.saveFolder(engagementLetterId, businessFolder);
  }

  getFolder(engagementLetterId: number): Observable<GetBusinessFolderModel> {
    return this.webApi.getFolder(engagementLetterId);
  }
}
