import { Injectable } from '@angular/core';
import { GetUser, UsersClient } from 'projects/difference/webapi/Difference.WebApi';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class UsersService {
  public meData: GetUser;

  constructor(private webApi: UsersClient) { }

  get(charteredOnly: boolean = true): Observable<GetUser[]> {
    return this.webApi.get(charteredOnly);
  }

  async me(): Promise<GetUser> {
    if (this.meData) {
      return new Promise(resolve => {
        resolve(this.meData);
      })
    } else {
      return this.webApi.me().pipe(
        map((response: GetUser) => {
          this.meData = response;
          return response;
        })
      ).toPromise();
    }
  }

}
